.navbar {
  background-color: transparent;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-left: 100px;
  padding-right: 115px;
}

.navbar ul {
  display: flex;
  gap: 51px;
  list-style: none;
}
.navbar-left {
  display: flex;
  padding-right: 40px;
}

.navbar-item-parent {
  display: none;
}

.navbar-item{
  cursor: pointer;
}
.navbar-items {
  color: #000;
  font-size: 1.375rem;
  font-family: PF Din Text Universal;
  font-weight: 700;
}

.button-parentt:hover {
  transform: scale(1.02);
}

.mobile-menu-icon {
  display: none;
}
@media screen and (max-width: 1024px) {
  .navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .navbar ul {
    display: flex;
    gap: 41px;
    justify-content: center;
    align-items: center;
  }
  .navbar-items {
    font-family: PF Din Text Universal;
    font-weight: 700;
  }
}

@media only screen and (max-width: 768px) {
  .navbar-items {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: white;
    transition: all 1s ease-out;
    height: auto;
    width: auto;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    z-index: 1;
    transform: translateY(-10px);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    top: 68px;
  }

  .navbar-items a {
    text-decoration: none;
    color: #000;
    font-size: 1.125rem;
    font-weight: bold;
    padding: 10px;
    border-bottom: 1px solid #e5e5e5;
    transition: background-color 0.3s ease;
  }

  .mobile-menu-icon {
    display: flex;
    transform: translateY(-50%);
    font-size: 1.5rem;
    color: #0049c9;
    cursor: pointer;
    padding-left: 10px;
    margin-top: 17px;
  }
  .navbar-items.mobile-open {
    display: none;
  }

  .navbar-left {
    display: none;
  }

  .button-parentt {
    display: none;
  }

  .navbar {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    height: 60px;
    width: 100%;
    position: fixed;
    z-index: 9999;
    gap: 80px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px;
  }

  .navbar-logo {
    width: 78px;
    height: 50px;
  }

  .button-parentt {
    display: flex;
  }
  .navbar-item-parent {
    display: flex;
  }
  .navbar-item33 {
    display: none;
  }
}
