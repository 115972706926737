.footer11 {
    background-image: url('../../../assets/fonts/backyellow.png');
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    gap: 120px;
    padding: 20px;


}

.footer11 ul {
    display: flex;
    flex-direction: column;
    gap: 10px;

}


.footer22 {

    background-color: white;
    height: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: right;
    font-size: 1.25rem;
    font-family: PF Din Text Universal;
    font-weight: 500;

}

.footer-item {
    color: #000;
    text-align: right;
    font-size: 1.1rem;
    font-family: PF Din Text Universal;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
}


.respoff {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex-direction: column;
}


.sociall {

    padding-top: 120px;

}



@media screen and (max-width: 768px) {


    .footer22 {
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;

    }

  

    .nova {
        height: auto;
        width: auto;

    }

    .footer-logo {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: auto;
        height: auto;

    }

    .khott {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;


    }

    .footer-logo {

        width: 61px;
        height: 61px;

    }


}