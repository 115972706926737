.nna {
  background-image: url('../../../assets/fonts/backg.png');
  padding-bottom: 60px;

}


.titerr {
  color: #000;
  text-align: right;
  font-size:  2rem;
  font-family: PF Din Text Universal;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: end;
  padding-right: 139px;
  padding-top: 70px;
  padding-bottom: 70px;

}

.rectangles {

  display: flex;
  justify-content: space-around;
  padding-right: 125px;
  padding-left: 125px;
  gap: 20px;

}

.rec1 {

  width: 352px;
  border-radius: 20px;
  background: #F9D923;

}

.rec2 {

  width: 352px;
  border-radius: 20px;
  background: #F9D923;

}

.rec3 {

  width: 352px;
  border-radius: 20px;
  background: #F9D923;

}

.rec1,
.rec2,
.rec3 {

  display: flex;
  justify-content: space-around;
  flex-direction: column;
  position: relative;
  align-items: center;
  height: 180px !important;
}

.box1-icon {
  width: 70px;
  position: absolute;
  top: -40px;
}



.p1 {
  color: #000;
  text-align: center;
  font-size: 1.5rem;
  font-family: PF Din Text Universal;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 30px;
}

.number,.numbeer{
  color: #404040;
  text-align: center;
  font-size: 1.25rem;
  font-family: PF Din Text Universal;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 40px;
  
}





.socials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 20px;

}



.contact-logo {
  height: 68.42px;
  width: 68.42px;
}

.contact-logo:hover {

  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  .rectangles {
    flex-direction: column-reverse;
    padding-right: 20px;
    padding-left: 20px;
    gap: 20px;
   
  }

  .rec1,
  .rec2,
  .rec3 {
    width: 100%;
    height: auto;
    position: relative;
  }



  .socials {
    padding-left: 0;
    padding-top: 10px;
    justify-content: center;
  }

  .titerr {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
  }

  .p1 {
    width: 100%;
    height: auto;
   
    padding: 10px;
    text-align: center;
    padding-top: 30px;
  }

  .number,.numbeer {
    width: 100%;
    height: auto;
    padding: 10px;
    text-align: center;
  }
  .numbeer{
    font-size: 1.12rem;
  }

  .rectangles {
    gap: 60px;
    padding-top: 40px;
  }

  .nna {
    padding-left:40px ;
    padding-right:40px ;
  
  }
}