.footer1 {
    background-image: url('../../../assets/fonts/backyellow.png');
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:210px;
    padding:40px;
    
}

.footer1 ul {
    display: flex;
    flex-direction: column;
    gap:8px;

}


.footer2 {

    background-color: white;
    height: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: right;
    font-size: 1.25rem;
    font-family: PF Din Text Universal;
    font-weight: 500;

}

.footer-item {
    color: #000;
    text-align: right;
    font-size: 1.25rem;
    font-family: PF Din Text Universal;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.footer-logo{
    height: 40.37px;
    width: 40.37px;
}
.footer-logo:hover{
    
    transform: scale(1.1);
}
.navbarr-logo{
    width: 100%;
}

.navbarr-logo:hover{
    
    transform: scale(1.1);
}



@media screen and (max-width: 1024px) {
 
  .footer1{
    gap:130px;
  }
  .footer-logo{
    width: 45px;
    height: 45px;
  }
}

@media screen and (max-width: 768px) {
    
    .footer2{
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    .nova{
        height: auto;
        width: auto;
        
    }
    

}

