.backgroundff {
  background-image: url("../../../assets/fonts/backgroundff.png");
  background-color: #f9d923;
  background-repeat: no-repeat;
  background-size: cover;

  overflow: hidden;
}

.msgR::-webkit-scrollbar,
.msgL::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.fix {
  background-image: url("../../../assets/fonts/backgroundff.png");
  background-color: #f9d923;
}

.titer {
  color: #000;
  text-align: right;
  font-size: 2rem;
  font-family: PF Din Text Universal;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}

.nav2 {
  background-color: transparent;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.userImage {
  min-width: 150px;
  min-height: 150px;
  max-height: 150px;
  max-width: 150px;
  border-radius: 50%;
  object-fit: fill;
}
.UserImage {
  min-width: 150px;
  min-height: 150px;
  max-height: 150px;
  max-width: 150px;
  border-radius: 50%;
}

.nav-item1 {
  color: #0049c9;
  text-align: center;
  font-size: 1.5rem;
  font-family: PF Din Text Universal;
  font-weight: 500;
}

.messages {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.msgR,
.msgL {
  display: flex;
  flex-direction: column;
  height: 400px;
  gap: 20px;
  overflow: scroll;
}

@media screen and (max-width: 768px) {
  .messages {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .show-msgR .msgR {
    display: flex;
  }

  .msgR-content {
    display: none;
  }

  .show-msgR .msgR .msgR-content {
    display: block;
  }

  .nav-item1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }

  .active {
    font-size: 1.625rem !important;
    font-weight: 600 !important;
  }
  .backgroundff {
    padding-left: 20px;
    padding-right: 20px;
  }

  .fix {
    padding-left: 10px;
    padding-right: 10px;
  }
  .nav2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
