.background {
    background-image: url('../../../assets/fonts/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: relative;

}

.green {
    background-image: url('../../../assets/fonts/green.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 2;


}


.backgroundOO {

    background-image: url('../../../assets/fonts/backg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
}

.topHome {
    position: relative;
    z-index: 9999999;
}


.Up {
    position: fixed;
    bottom: 20px;
    right: 60px;
    z-index: 999;
    width: 33px;
    height: 50px;
    flex-shrink: 0;
    background: #0049C9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.507) 0px 1px 4px;
}

.flech {

    animation: slideUpDown 2s infinite ease-in-out alternate;
}

@keyframes slideUpDown {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-3px);
    }

}





@media screen and (max-width: 1024px) {

    .background {

        height: 100vh;
        width: 100%;

    }

    .green {
        height: 100%;
        width: 100%;
    }

    .backgroundOO {
        height: 100%;
        width: 100%;
        overflow: hidden;

    }


}

@media screen and (max-width: 768px) {

    .background {
        height: 100%;
        width: 100%;
    }

    .green {
        height: 100%;
        width: 100%;
    }



    .Up {
        
        bottom: 20px;
        right: 15px;
        z-index: 999;
        width: 23px;
        height: 40px;
        flex-shrink: 0;
        background: #0049C9;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.507) 0px 1px 4px;
    }

    .flech {
        height: auto;
        width: auto;

    }
    .backgroundOO {
        background-size: contain;
      

    }
   
}