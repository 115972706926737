.all {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 60px;
    background-image: url('../../../assets/fonts/backg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;


}

.sec1,
.sec5,
.sec2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 180px;


}

.sec3 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 180px;
    padding-right: 20px;
}

.sec4 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 150px;
    padding-bottom: 80px;


}

.pa {

    display: flex;
    justify-content: end;
    align-items: end;
    flex-direction: column;

}

.mmapp {
    color: #000;
    text-align: right;
    font-size: 2rem;
    font-family: PF Din Text Universal Medium;
    font-weight: 700;
    display: flex;
    justify-content: end;
    align-items: end;
    padding-right: 130px;

}

.ttapp,
.ttappp {
    color: #0049C9;
    text-align: right;
    font-size: 1.5rem;
    font-family: PF Din Text Universal;
    font-weight: 500;
    padding-bottom: 15px;

}

.parag {
    color: #000;
    text-align: right;
    font-size: 1.125rem;
    font-family: PF Din Text Universal;
    display: flex;
    width: 536px;
    flex-direction: column;
    gap: 5px;



}

.frame {
    width: 370.81px;
    height: 321px;

}

.frame2 {

    width: 408px;
    height: 321px;


}

.real {
    width: 327px;
    height: 362px;

}

.pyramide {
    width: 404px;
    height: 335px;



}

.parents {
    width: 408px;
    height: 366px;

}

.coll {
    color: #FF0000;
}

@media only screen and (max-width: 1024px) {

    .all {
        padding-left: 20px;
        padding-right: 20px;
    }

    .mmapp {
        display: flex;
        justify-content: end;
        align-items: center;
        padding: 20px;
        padding-bottom: 60px;
        padding-right: 28px;
        text-align: center;

    }

    .all {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        gap: 60px;


    }

    .sec1,
    .sec2 {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;
    }

    .sec4 {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }

    .sec5 {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 80px;

    }

    .sec3 {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 60px;

    }

    .pyramide {
        width: 380px;
        height: 335px;
    }


}

@media only screen and (max-width: 768px) {


    .all {
        background-size: contain;
     background-repeat: repeat;
    
    }

    .sec1,
    .sec5,
    .sec4 {
        flex-direction: column;
        gap: 40px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
    }

    .sec2,
    .sec3 {
        flex-direction: column-reverse;
        gap: 40px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
    }

    .pa {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;



    }

    .ttapp {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 1.1rem;

    }

    .parag {
        display: flex;
        justify-content: center;
        text-align: right;
        
        width: 100%;
        font-size: 1rem;

    }


    .frame {
        width: 270.81px;
        height: 221px;

    }

    .frame2 {

        width: 308px;
        height: 221px;
    }

    .real {
        width: 227px;
        height: 262px;

    }

    .pyramide {
        width: 404px;
        height: 335px;
        padding-left: 22px;
    }

    .parents {
        width: 308px;
        height: 266px;

    }

    .mmapp {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        padding-bottom: 60px;
        text-align: center;
    }

}