.background2 {
    background-image: url('../../../assets/fonts/cercelll.png'), url('../../../assets/fonts/hamouda.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 778px;
    width: 100%;

}

.background3 {
    background-image: url('../../../assets/fonts/hamouda.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 778px;
    width: 100%;
}

.nav {
    background-color: white;
    height: 120px;
    display: flex;
    justify-content: end;
    padding-right: 120px;
    align-items: center;

}

.nav-item {
    color: #000;
    text-align: right;
    font-size: 2rem;
    font-family: PF Din Text Universal;
    font-weight: 700;
}

.icons1 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 60px;
}

.icons2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 60px;
    text-align: center;
}


.iconss {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    gap: 40px;
}


.text1,
.text2,
.text3,
.text4,
.text5,
.text6 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #000;
    font-size: 1.5rem;
    font-family: "PF Din Text Universal";
    font-weight: 500;
    height: 250px;
    justify-content: space-between;

}

.text1 img,
.text2 img,
.text3 img,
.text4 img,
.text5 img,
.text6 img {
    height: 200px;
    width: 200px;
    object-fit: contain;
}







.text2{
   
    padding-top: 15px ;
}
@media only screen and (max-width: 1024px) {

   
    .nav {
        background-color: white;
        height: 120px;
        display: flex;
        justify-content: end;
        padding-right: 28px;
        align-items: center;

    }
    .iconss {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        gap: 40px;
       
    }
    
  }



@media only screen and (max-width: 768px) {

    .respo{
        display: none;
    }
  
    
  }




