.avatar {
  animation: slideUpDown 2s infinite ease-in-out alternate;
}
@keyframes slideUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}

.about {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 150px; */
  padding-right: 115px;
  padding-left: 80px;
  z-index: 9999;
}

.step {
  color: #000;
  text-align: right;
  font-size: 2.5rem;
  font-family: pfdin;
  font-weight: 700;
  padding-bottom: 25px;
}

.we {
  color: #000;
  text-align: right;
  font-size: 2rem;
  font-family: pfdin;
  font-weight: 700;
  padding-bottom: 10px;
}

.para {
  color: #323232;
  text-align: right;
  font-size: 1.25rem;
  font-family: pfdin;
  font-weight: 700;
  display: flex;
  width: 510px;
  flex-direction: column;
  padding-bottom: 35px;
}

.jouer {
  color: #000;
  text-align: right;
  font-size: 2rem;
  font-family: pfdin;
  font-weight: 700;
  padding-bottom: 20px;
  padding-top: 20px;
}

.icons {
  display: flex;
  gap: 10px;
  align-items: end;
  justify-content: right;
}

.all-para {
  display: flex;
  justify-content: end;
  align-items: end;
  flex-direction: column;
  padding-top: 40px;
}

.web,
.store,
.appel,
.huawei {
  width: 72px;
}
.web:hover,
.store:hover,
.appel:hover,
.huawei:hover {
  transform: scale(1.1);
}

/* Responsive styles */

@media screen and (max-width: 1024px) {
  .about {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 90px;
  }
}
@media screen and (max-width: 768px) {
  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .about_desc {
    width: 250px;
  }
  .icons {
    justify-content: center;
    height: 70%;
    width: 70%;
  }

  .all-para {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .para {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .step,
  .we,
  .para,
  .jouer {
    text-align: center;
    padding-bottom: 15px;
  }
}
