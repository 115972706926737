.Box_Mo {
    border-radius: 20px;
    background: #0049C9;
    padding: 15px;
    height: auto;
    width: auto;
}

.info_Mo {
    display: flex;
    justify-content: space-around;
    align-items: start;
    gap: 10px;
    padding: 10px;
    flex-direction: column;
}

.txt_Mo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 80px;  
}

.img_rate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
 
    
   
    

}

.txte_Mo {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: auto;
}

.name_Mo {
    color: #FFF;
    text-align: right;
    font-family: PF Din Text Universal;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 6px;
}

.prof_Mo {
    color: #F9D923;
    text-align: right;
    font-family: PF Din Text Universal;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 12px;
    width: 100%;
}

.msg_Mo {
    flex-shrink: 0;
    color: #FFF;
    text-align: right;
    font-family: PF Din Text Universal;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.rate_Mo {
    background-color: #FFF;
    border-radius: 10px;
    width: 168px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stars_Mo {
    display: flex !important;
    padding-top: 16px;
    justify-content: space-around;
    gap: 6px;
}

.stars_Mo label {
    font-size: 1.875rem;
    padding-bottom: 20px;
}

.stars_Mo i {
    float: right;
    cursor: default;
}

.image_Mo {
    height: 100%;
    width: 100%;
    
}

/* Responsive Styles */

@media screen and (max-width: 768px) {
    .Box_Mo {
        height: auto;
        width: auto;
    }


}