.Box {
    width: 535px;
    border-radius: 20px;
    background: #0049C9;
    padding: 15px;
    height: 270px;
}

.info {
    display: flex;
    justify-content: space-around;
    align-items: start;
    gap: 10px;
    padding: 10px;
}


.txt {
    display: flex;
    justify-content: end;
    align-items: end;
    flex-direction: column;
}

.name {
    color: #FFF;
    text-align: right;
    font-family: PF Din Text Universal;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 6px;
}

.prof {
    color: #F9D923;
    text-align: right;
    font-family: PF Din Text Universal;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 12px;

}

.msg {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    color: #FFF;
    text-align: right;
    font-family: PF Din Text Universal;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.rate {
    background-color: #FFF;
    border-radius: 10px;
    width: 168px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.stars {
    display: flex !important;
    padding-top: 16px;
    justify-content: space-around;
    gap:6px
}

.stars label {
    font-size: 1.875rem;
    padding-bottom: 20px;
    
}
 
.stars i{
    float: right;
    cursor: default;
    
}

.txte{
    display: flex;
    flex-direction: column;
    gap:20px;
    justify-content: center;
    align-items: center;
  
    
    
}
@media only screen and (max-width: 1024px) {

    .Box{
        height: auto;
        width: auto;
    }
}
@media screen and (max-width: 768px){

    .Box{
        height: auto;
        width: auto;
    }

  
}