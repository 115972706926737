.download {
    background-image: url('../../../assets/fonts/BACKGROUNDD.png');
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 250px;
    padding-right: 60px;
    padding-left: 10px;
}

.stepp {
    color: #FFF;
    text-align: right;
    font-size: 3rem;
    font-family: PF Din Text Universal;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 30px;
}



.paraa {
    color: #FFF;
    text-align: right;
    font-size: 1.5rem;
    font-family: PF Din Text Universal;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 20px;

}


.iconsss {
    display: flex;
    flex-direction: row;
    gap: 10px;



}

.alll-para {
    display: flex;
    justify-content: end;
    align-items: end;
    flex-direction: column;
    padding-right: 60px;

}

@media only screen and (max-width: 1024px) {

    .alll-para {
        display: flex;
        justify-content: end;
        align-items: end;
     
        
    }
    .download {
     
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap:20px;
        padding: 20px;
       
    }
    
}

@media screen and (max-width: 768px) {

    .download {
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
        align-items: center;
        gap: 40px;
       
    }

    .alll-para {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        padding-top: 40px;
        padding-left: 60px;
       
        
        
    }

    .stepp {
        text-align: center;
        font-size: 1.77rem;
    }

    .paraa {
        text-align: center;
        font-size: 1.3rem;
    }

    .phone {
        width: 100%;
    }
   
}